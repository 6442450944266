<template>
  <div class="home">
    <span id="home" class="anchor"></span>
      <div class="fiveStarback">
        <b-navbar toggleable="lg" type="dark" sticky="true">
            <b-navbar-brand href="#home"
              ><b-img
                class="logo"
                :src="config.VUE_APP_DIGITAL_OCEAN+'2019/04/5star-header.png'"
              ></b-img
            ></b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-item href="#be-inspired" :class="{active: show === false}" @click="blink(false)" class="link"
                  >BE INSIPIRED</b-nav-item
                >
                <b-nav-item href="#players" :class="{active: show === false}" @click="blink(false)" class="link">PLAYERS</b-nav-item>
                <b-nav-item href="#events" :class="{active: show === false}" @click="blink(false)" class="link">EVENTS</b-nav-item>
                <b-nav-item href="#contact" :class="{active: show === false}" @click="blink(false)" class="link">CONTACT</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
        </b-navbar>
      </div>
    <div class="splash">
      <h1 class="headerText">
        <span class="boldHeaderText">{{ acf.acf.bold_title_text }} </span
        >
        <span class="sportAgency-title">{{ acf.acf.title_text }}</span>
      </h1>
      <div class="sep"></div>
      <h3 class="headerSub">
        {{ acf.acf.tagline }}
      </h3>
    </div>
    <span id="be-inspired" class="anchor"></span>
    <section id="be-inspired" class="secOneGen">
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <h3 class="secOneTitle">
                {{ acf.acf.section_1_title }}
              </h3>
              <b-row class="sepBlueAbout"></b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span class="secOneText" v-html="acf.acf.section_1_text"></span>
            </b-col>
          </b-row>
          <div class='secOneBtn'>
              <b-button href="#events">OUR EVENTS</b-button>
          </div>
        </b-col>
        <b-col class='mobHide'> </b-col>
      </b-row>
    </section>

    <span id="players" class="anchor"></span>
    <section id="players" class="secTwoGen">
      <b-row>
        <b-col>
          <h3 class="secTwoTitle">
            {{ acf.acf.section_2_title }}
          </h3>
          <b-row class="sepBluePlayers"></b-row>
        </b-col>
        <b-col>
          <div class="secTwoInfo">
            <font-awesome-icon id="fa" class="icon" :icon="['fa', 'star']" />
            <font-awesome-icon id="fa" class="icon" :icon="['fa', 'star']" />
            <font-awesome-icon id="fa" class="icon" :icon="['fa', 'star']" />
            <font-awesome-icon id="fa" class="icon" :icon="['fa', 'star']" />
            <font-awesome-icon id="fa" class="icon" :icon="['fa', 'star']" />
          </div>
        </b-col>
      </b-row>
      <b-row class="secTwoProfile">
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_1"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_1_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_1_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_2"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_2_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_2_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_3"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_3_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_3_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_4"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_4_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_4_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_5"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_5_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_5_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_6"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_6_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_6_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_7"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_7_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_7_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_8"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_8_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_8_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_9"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_9_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_9_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_10"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_10_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_10_position}}</div>
        </b-col>
        <b-col lg="3" class='gapTop'>
          <b-img class="secTwoImg" :src="acf.acf.section_2_image_11"></b-img>
          <div class='secTwoName'>{{acf.acf.section_2_player_11_name}}</div>
          <div class='secTwoRole'>{{acf.acf.section_2_player_11_position}}</div>
        </b-col>
      </b-row>
    </section>

    <section id='imagePanel'></section>

    <span id="events" class="anchor"></span>
    <section id="events" class='secThreeGen'>
      <b-row>
        <b-col lg='7'>
          <h3 class='secThreeTitle'>
            {{acf.acf.section_3_title}}
          </h3>
          <b-row class="sepWhite sepGap"></b-row>
        </b-col>
        <b-col class='twitterSec' lg='5'>
          <b-row>
            <b-col cols='12'>
              <b-row>
                <b-col cols='12'>
              <div class="twitterText">
                Follow us on Twitter
              <a :href="'https://twitter.com/'+ config.VUE_APP_TWITTER_NAME +'?lang=en'">
              <font-awesome-icon id="fab" class="twitterIcon" :icon="['fab', 'twitter']" />
              </a>
              </div>
                </b-col>
              </b-row>
              <b-row>
              <b-col cols='12'>
              <div class='twitterTag'>
                <a class="twitterTag">
                {{acf.acf.twitter_tag}}
                </a>
              </div>
            </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class='secThreeEntry'>
        <b-col lg='4'>
          <b-img class='secThreeImg' :src="acf.acf.section_3_article_1_image"></b-img>
          <div class="eventTitle">{{acf.acf.section_3_article_1_title}}</div>
          <div class="eventText" v-html="acf.acf.section_3_article_1_text"></div>
        </b-col>
        <b-col lg='4'>
          <b-img class='secThreeImg' :src="acf.acf.section_3_article_2_image"></b-img>
          <div class="eventTitle">{{acf.acf.section_3_article_2_title}}</div>
          <div class="eventText" v-html="acf.acf.section_3_article_2_text"></div>
        </b-col>
        <b-col lg='4'>
          <b-img class='secThreeImg' :src="acf.acf.section_3_article_3_image"></b-img>
          <div class="eventTitle">{{acf.acf.section_3_article_3_title}}</div>
          <div class="eventText" v-html="acf.acf.section_3_article_3_text"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button href="#contact" class='secThreeBtn'>GET IN TOUCH</b-button>
        </b-col></b-row>
    </section>

    <span id="contact" class="anchor"></span>
    <section id="contact" class='secFourGen'>
      <b-row>
        <b-col lg='6'>
          <h3 class='secFourTitle'>
            {{acf.acf.section_4_title}}
          </h3>
          <b-row class="sepBlue sepGap"></b-row>
        </b-col>
        <b-col lg='6' class="emailLineInfo">
          <div class="contactInfo">
            email <a href="mailto:michael@paullawriegolf.com" class='contactEmail'>{{acf.acf.email_address}}</a>
          </div>
          <div class='contactInfo contactInfoTwo'>or complete the form below</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Form />
        </b-col>
      </b-row>
    </section>
    <div class="FooterBack">
      <b-row>
        <b-col>
          <Footer :config="config"/>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from '@/components/form.vue';
import Footer from '@/components/footer.vue'

export default {
  name: "Home",
  props:['config'],
  components:{
    Form,
    Footer
  },
  data: function() {
    return {
      acf: [],
      show:true
    };
  },
  methods:{
    blink(value){
      if(value === false){
        this.show = false,
        setTimeout(() => {this.show = true},250)
      }
    }
  },
  mounted() {
    axios //add API Call
      .get(process.env.VUE_APP_API_URL+"wp/v2/pages/10") // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.acf = response.data)); //API data wanted pulled out
  },
};
</script>

<style scoped>
.fiveStarback {
  background-color: #0e51a7;
  display: block;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.FooterBack {
  background-color: #001621;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.navbar-brand{
  padding-left: 39px;
}
.btn{
  border-radius: 0;
  border-color: transparent;
}
::v-deep .navbar {
  /* background-color: #0E51A3; */
  opacity: 0.95;
}
.logo {
  width: 205px !important;
  height: 68px !important;
}
.link {
  font-family: "Oswald", sans-serif;
  padding: 0.5rem;
  font-weight: 200;
  font-size: 22px;
}
.link > a {
  position: relative;
  overflow: hidden;
  color: #fff !important;
  transition: ease-in-out color .5s;
  transition: ease-in-out max-height .4s;
  max-height: 50px;
  font-weight: 300;
}
.link>a:hover{
 color: #001621!important;
 transition: ease-in-out color .5s;
}

.link.active>a{
  position: relative;
  overflow: hidden;
  max-height: 0px;
  transition: ease-in-out max-height .4s;
}
.splash {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 800px;
  background-image: url(https://5starsportsagency.com/wp-content/uploads/2019/03/Paul_Lawrie_Managment_BlueHero-e1555346101514.jpg);
  background-repeat: no-repeat;
  background-position: 50% 45%;
  background-size: cover;
  border-radius: 0;
}
.headerText {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  font-size: 60px;
  padding-top: 200px;
  text-transform: uppercase;
  padding-bottom: 20px;
}
.boldHeaderText {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 60px;
  padding-left: 55px;
}
.sep {
  height: 3px;
  width: 150px;
  border: 1px solid #fff;
  margin-left: 55px;
  margin-bottom: 10px;
  padding: 0px;
  background-color: #fff;
}
.headerSub {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 34px;
  padding-left: 55px;
  text-transform: none;
  line-height: 1.25;
  padding-top: 20px;
}
.secOneTitle {
  color: #000;
  text-transform: uppercase;
  font-size: 32px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  padding: 40px 30px 0px 30px;
}
.sepBlue {
  height: 3px;
  width: 100px;
  border: 1px solid #0E51A3;
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  background-color: #0E51A3;
}
.sepBluePlayers {
  height: 3px;
  width: 100px;
  border: 1px solid #0E51A3;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  background-color: #0E51A3;
}
.sepBlueAbout {
  height: 3px;
  width: 100px;
  border: 1px solid #0E51A3;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  background-color: #0E51A3;  
}
.secOneGen {
  background-color: #fff !important;
  margin: 0px;
  padding-left: 30px;
  padding-right: 0px;
  padding-top: 0px;
  color: #808182;
}
::v-deep .secOneText > p {
  font-family: "Open sans", sans-serif;
  font-weight: 300;
  font-size: 14px;
  padding: 0px 60px 0px 30px;
  line-height: 2.2;
}
.secTwoGen {
  background-color: #001621;
  margin: 0px;
  padding-left: 30px;
  padding-right: 0px;
  padding-top: 20px;
  padding-bottom: 50px;
}
.secTwoTitle {
  color: #fff;
  text-transform: uppercase;
  font-size: 32px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  padding: 40px 30px 0px 30px;
}
.secTwoInfo {
  color: #fff;
  float: right;
  padding-top: 65px;
  padding-right: 55px;
}
.fa {
  font-weight: 900;
}
.secTwoProfile {
  margin-left: 12px;
  margin-right: 45px;
  padding-top: 0px;
}
.gapTop {
  margin-top: 20px;
}
.row.secThreeEntry {
  padding-left: 30px;
  padding-right: 30px;
}
.secTwoImg {
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
}
.secTwoName {
  color: #0b4a9a;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 20px;
  padding-left: 0px;
}
.secTwoRole {
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  text-transform: none;
  font-size: 14px;
  margin-top: 5px;
  padding-left: 0px;
}
.secOneBtn>a{
  margin-top: 20px;
  margin-left: 30px;
  margin-bottom: 50px;
  padding: 8px 30px;
  font-size: 14px;
  font-family: 'Oswald', sans-serif;
  color: #fff!important;
  background-color: #0E51A3;
}
#imagePanel{
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 300px!important;
  background-image: url(https://ocs-sport.ams3.digitaloceanspaces.com/fivestar-wordpress/2019/03/bunker.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.anchor{
  display: block;
  height: 100px;
  margin-top: -100px;
  visibility: hidden;
}
.secThreeGen{
  background-color: #0E51A3;
  margin: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0px;
  padding-bottom: 50px;
}
.twitterSec{
  float: right;
  text-align: right;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 35px;
}
.secThreeTitle{
  color: #fff;
  text-transform: uppercase;
  font-size: 32px;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  padding: 40px 30px 0px 30px;
}
.sepWhite{
  height: 3px;
  width: 100px;
  border: 1px solid #fff;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  background-color: #fff;
}
.sepGap{
  /* margin-top: -25px!important; */
  margin-bottom: 40px;
}
.setThreeEntry{
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 20px;
}
.secThreeImg{
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px;
}
.eventTitle{
  font-family: 'Oswald', sans-serif;
  font-size: 22px;
  font-weight: 200;
  text-transform: uppercase;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 0px;
  line-height: 1.25;
}
.eventText{
  font-family: 'Open-sans', sans-serif;
  color: #fff;
  font-size: 14px;
  margin-left: 0px;
  margin-right: 0px;
  line-height: 2.2;
}
.secThreeBtn{
  margin-top: 20px;
  margin-left: 30px;
  margin-bottom: 50px;
  padding: 8px 30px;
  font-size: 14px;
  font-family: 'Oswald', sans-serif;
  color: #fff!important;
  background-color: #001621;
}
.twitterText{
  font-family: 'Oswald', sans-serif;
  font-size: 22px;
  font-weight: 200;
  text-align: right;
  margin-right: 5px;
  color: #fff;
}
.twitterTag{
  font-family: 'Oswald', sans-serif;
  font-size: 22px;
  font-weight: 200;
  text-align: right;
  margin-right: 20px;
  margin-top: -14px;
  color: #001621!important;
}
.twitterIcon{
  font-size: 52px;
  color: #fff;
  padding-top: 12px;
  margin-right: -20px;
  padding-left: 20px;
  vertical-align: middle;
}
.secFourGen{
  background-color: #eaeef2;
  margin: 0px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 0px;
  padding-bottom: 20px;
  color: #000;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 24px;
}
.secFourTitle{
  color: #000;
  text-transform: uppercase;
  font-size: 32px;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  padding: 40px 30px 0px 0px;
}
.contactInfo{
  font-size: 22px;
  font-family: 'Oswald', sans-serif;
  color: #000!important;
  text-align: right;
  padding-right: 15px;
  padding-top: 50px;
}
.contactInfoTwo{
  padding-top: 0px;
}
.contactEmail{
  font-size: 22px;
  font-family: 'Oswald', sans-serif;
  color: #0E51A3!important;
  text-decoration: none;
}
@media only screen and (max-width: 425px) {
  .boldHeaderText{
    font-size: 30px;
  }
  .headerText{
    font-size: 30px;
  }
  .headerSub{
    font-size: 23px;
  }
  .mobHide{
    display: none;
  }
  .secOneGen {
    background-color: #fff !important;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    color: #808182;
  }
  .secThreeTitle {
    padding-left: 0px;
  }
  .sepGap {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 990px) {
  .contactInfo {
      font-size: 22px;
      font-family: 'Oswald', sans-serif;
      color: #000!important;
      text-align: left!important;
      padding-right: 15px;
      padding-top: 0px;
  }
  .emailLineInfo {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 657px) {
  .sportAgency-title {
    display: block;
    padding-left: 55px;
  }
}
@media only screen and (max-width:500px) {
  .splash {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: auto;
    background-image: url(https://5starsportsagency.com/wp-content/uploads/2019/03/Paul_Lawrie_Managment_BlueHero-e1555346101514.jpg);
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: cover;
    border-radius: 0;
  }
  .headerText {
    color: #fff;
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    font-size: 45px;
    padding-top: 80px;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  .headerSub {
    color: #fff;
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 20px;
    padding-left: 55px;
    text-transform: none;
    line-height: 1.25;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .twitterSec {
    display: none;
  }
  .row.secThreeEntry {
    padding-left: 0px;
    padding-right: 0px;
  }
  .secFourGen {
    background-color: #eaeef2;
    margin: 0px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0px;
    padding-bottom: 20px;
    color: #000;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 24px;
  }
  button.btn.button.sub.btn-primary {
    border-radius: 0px;
  }
  .secThreeBtn {
    margin-top: 20px;
    margin-left: 0px;
    margin-bottom: 50px;
    padding: 8px 30px;
    font-size: 14px;
    font-family: 'Oswald', sans-serif;
    color: #fff!important;
    background-color: #001621;
  }
}
</style>
