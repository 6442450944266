<template>
    <div class="backroundColor">
        <b-row class="footerDetails">
            <b-col class="footerLeftText">@2021 Five Star Sports Agency | Designed by <a href="https://ocs-sport.com" target="_blank">OCS Software</a></b-col>
            <b-col class="twitterFind">
              <font-awesome-icon id="fab" class="twitterIcon" :icon="['fab', 'twitter']" /> Find us on <a :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME" target="_blank">Twitter</a>
              <img class="fivestarLogo" :src="config.VUE_APP_DIGITAL_OCEAN+'2019/04/Paul_Lawrie_WhiteLogo.png'">
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  props:['config']
}
</script>

<style scoped>
.footerLeftText {
    line-height: 3.9em;
}
.twitterFind {
    text-align: right;
}
.fivestarLogo {
    width: 130px;
    margin-left: 30px;
}
.footerDetails {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
}
::v-deep textarea.form-control {
  border: none;
  height: 200px!important;
}
.gOne{
  padding: 0 0px;
}
input{
  width: 100%;
  border: none;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 14px;
  border-radius: 3px;
  padding-left: 15px;
  margin-bottom: 29.5px;
}
.sub{
  /* margin-left: 45px; */
  width: 100px;
  background-color: #0e51a7;
  margin-bottom: 60px;
}
@media only screen and (max-width: 768px) {
 .gOne{
   padding: 20px 0;
 }
}
@media only screen and (max-width: 920px) {
    .footerLeftText {
        line-height: inherit;
    }
}
</style>
