<template>
  <div class="request-a-callback-form">
    <transition name="fade" mode="out-in">
      <div v-show="sent">
        <p>Thanks for contacting us, we will get back to you shortly...</p>
      </div>
    </transition>

    <form class="blue" @submit="onSubmit">
      <b-row>
        <b-col lg='6' cols='12'>
          <div class='gOne'>
          <input type="text" v-model="names" placeholder="Name: (Required)" />
          <input type="text" v-model="email" placeholder="Email: (Required)" />
          <input type="text" v-model="phone" placeholder="Phone Number:" />
          </div>
        </b-col>
        <b-col lg='6' cols='12'>
          <textarea v-model="message" rows="8" cols="1" class="form-control" placeholder="Enquiry:">
          </textarea>
        </b-col>
      </b-row>
      <br />
      <button
        data-text="submit"
        type="submit"
        class="btn button sub btn-primary"
      >
        SEND
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      sent: false,
      contact: true,
      names: "",
      email: "",
      phone: "",
      message: "",
    };
  },
  props: ["contactBlue"],
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("names", this.names);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("message", this.message);
      console.log(formData);
      axios
        .post(
          process.env.VUE_APP_API_URL+"contact-form-7/v1/contact-forms/143/feedback",
          formData
        )
        .then((response) => {
          this.sent = true;
          return response;
        });
    },
  },
};
</script>

<style scoped>
::v-deep textarea.form-control {
  border: none;
  height: 200px!important;
  border-radius: 0;
}
.gOne{
  padding: 0 0px;
}
input{
  width: 100%;
  border: none;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 14px;
  border-radius: 3px;
  padding-left: 15px;
  margin-bottom: 29.5px;
  border-radius: 0;
}
.sub{
  /* margin-left: 45px; */
  width: 100px;
  background-color: #0e51a7;
  margin-bottom: 60px;
  border-radius: 0px;
}
@media only screen and (max-width: 768px) {
  .gOne{
    padding: 20px 0;
  }
}
</style>
